@use '@/shared/ui/button/adaptive' as *;
@use '@fixed-size' as *;
@use '@queries' as *;
@use '@typography' as *;

.select-btn {
  @include buttonSizes('34', '44', '44', '42', '46', '46');

  padding: 8px 20px;

  @include min-428-break {
    padding: 11.5px 20px;
  }

  @include min-1024-break {
    padding: 10.5px 20px;
    min-width: 129px;
  }

  @include min-1440-break {
    padding: 12.5px 20px;
    min-width: 131px;
    border-radius: var(--cornerradius10);
  }
}

.select-mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  @include min-428-break {
    gap: 14px;
  }
}

.text-mobile {
  @include typography-base;
  @include weight-semi-bold;

  @include min-428-break {
    @include typography-m;
  }
}
